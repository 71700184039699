import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ResourceActionService } from '../base/resource-action.service';

@Injectable({
  providedIn: 'root'
})
export class AppApiPaymentTypeService extends ResourceActionService {
  url = '/paymentTypes';
}
