import { Injectable } from '@angular/core';
import { ResourceActionService } from '../../services/base/resource-action.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class SettingService extends ResourceActionService {
  url = '/settings';
  settingData: Observable<any>;
  settingDataSubject: BehaviorSubject<any>;

  constructor(
    public http: HttpClient,
    private authenticationService: AuthenticationService,
  ) {
    super(http);
    this.settingDataSubject = new BehaviorSubject<any>(this.authenticationService.getLocalStorageItem('settingData'));
    this.settingData = this.settingDataSubject.asObservable();
  }

  public get settingDataValue(): any {
    const temp = this.settingDataSubject.value;
    if (temp && temp.id) {
      return this.settingDataSubject.value;
    }
    return null;
  }

  getByCode(code) {
    this.http.get(environment.backendUrl + '/api' + this.url + '/code/' + code).subscribe(
      (resource: any[]) => {
        this.resource = resource;
        this.emitResourceSubject();
      },
      (error) => {
      },
    );
  }

}
