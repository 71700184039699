import { User } from './../../models/user';
import { Job } from './../../models/job';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ResourceActionService } from '../../services/base/resource-action.service';
import { AppApiService } from '../../services/app-api/app-api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
securityActionResultSubject = new Subject<any>();
//  Jobs
jobSubject = new Subject<any[]>();
private jobs: Job[] = [];
// Job
jobSingleSubject = new Subject<any>();
job: any;
// Roles
roleSubject = new Subject<any[]>();
private roles: any[] = [];
// Role
roleSingleSubject = new Subject<any>();
role: any;
// Account types
accountTypeSubject = new Subject<any[]>();
private accountTypes: any[] = [
];
//  Users
userSubject = new Subject<any[]>();
private users: any[] = [];
// user
userSingleSubject = new Subject<any>();
user: any;

jobActionResultSubject = new Subject<any>();
jobActionResult: any = {
  // type: 'update',
  // message: 'success',
};

roleActionResultSubject = new Subject<any>();
roleActionResult: any = {
  // type: 'update',
  // message: 'success',
};

userActionResultSubject = new Subject<any>();
userActionResult: any = {
  // type: 'update',
  // message: 'success',
};
emitUserActionResultSubject() {
  this.userActionResultSubject.next(this.userActionResult);
}

emitRoleActionResultSubject() {
  this.roleActionResultSubject.next(this.roleActionResult);
}

emitJobActionResultSubject() {
  this.jobActionResultSubject.next(this.jobActionResult);
}

// Account types
emitAccountTypeSubject() {
  this.accountTypeSubject.next(this.accountTypes.slice());
}
getAccountTypes() {
  this.api.getAccountTypes().subscribe(
    (accountTypes: any[]) => {
      this.accountTypes = accountTypes;
      // console.log(accountTypes);
      this.emitAccountTypeSubject();
    },
    (error) => {
    },
  );
}


// Role
emitSingleRoleSubject() {
  this.roleSingleSubject.next(this.role);
}

// Role
emitRoleSubject() {
  this.roleSubject.next(this.roles.slice());
}
//  Jobs
emitJobSubject() {
  this.jobSubject.next(this.jobs.slice());
}
// Job
emitSingleJobSubject() {
  this.jobSingleSubject.next(this.job);
}

//  User
emitUserSubject() {
  this.userSubject.next(this.users.slice());
}
// user
emitSingleUserSubject() {
  this.userSingleSubject.next(this.user);
}

emitSingleUserByParamsSubject() {
  this.userSingleSubject.next(this.user);
}

constructor(public api: AppApiService, public http: HttpClient) {
}


// Roles
getRoles(account_type_id: number) {
  this.api.getRoles(account_type_id).subscribe(
    (roles: any[]) => {
      this.roles = roles;
      this.emitRoleSubject();
    },
    (error) => {
    },
  );
}
getRole(id: number) {
  this.api.getRole(id).subscribe(
    (role: any) => {
      this.role = role;
      this.emitSingleRoleSubject();
      // console.log(role);
    },
    (error) => {
    },
  );
}
// Jobs
getJobs() {
  this.api.getJobs().subscribe(
    (jobs: Job[]) => {
      this.jobs = jobs;
      this.emitJobSubject();
    },
    (error) => {
    },
  );
}
getJob(id: number) {
  this.api.getJob(id).subscribe(
    (job: Job) => {
      this.job = job;
      this.emitSingleJobSubject();
      // console.log(job);
    },
    (error) => {
    },
  );
}
deleteJob(id: number) {
  this.api.deleteJob(id).subscribe(
    (job: Job) => {
      // this.emitSingleJobSubject();
      this.getJobs();
    },
    (error) => {
    },
  );
}
addJob(newJob: Job) {
  this.api.addJob(newJob).subscribe(
    (job: Job) => {
      this.jobs.unshift(job);
      // this.emitJobSubject();
      this.getJobs();
      this.jobActionResult = {
        type: 'add',
        message: 'success',
      };
      this.emitJobActionResultSubject();

    },
    (error: any) => {
    },
  );
}
updateJob(id, newJob: Job) {
  this.api.updateJob(id, newJob).subscribe(
    (job: Job) => {
      this.jobs.unshift(job);
      // this.emitJobSubject();
      this.jobActionResult = {
        type: 'update',
        message: 'success',
      };
      this.emitJobActionResultSubject();

    },
    (error: any) => {
    },
  );
}
updateRole(id, newRole: any) {
  this.api.updateRole(id, newRole).subscribe(
    (role: any) => {
      this.role = role;
      // console.log('update role', role);
      this.roleActionResult = {
        type: 'update',
        message: 'success',
      };
      this.emitRoleActionResultSubject();
    },
    (error: any) => {
    },
  );
}
// User
getUsers() {
  this.api.getUsers().subscribe(
    (users: User[]) => {
      this.users = users;
      this.emitUserSubject();
    },
    (error) => {
    },
  );
}
getUser(id: number) {
  this.api.getUser(id).subscribe(
    (user: User) => {
      this.user = user;
      this.emitSingleUserSubject();
      // console.log(user);
    },
    (error) => {
    },
  );
}
deleteUser(id: number) {
  this.api.deleteUser(id).subscribe(
    (user: User) => {
      // this.emitSingleUserSubject();
      this.getUsers();
    },
    (error) => {
    },
  );
}
getUserByParams(params = {}) {
  this.http.get<User>(environment.backendUrl + '/api/users', {params: params}).subscribe(
    (user: User) => {
      this.user = user;
      this.emitSingleUserByParamsSubject();
      // console.log(user);
    },
    (error) => {
    },
  );
}
addUser(newUser: User) {
  this.api.addUser(newUser).subscribe(
    (user: User) => {
      this.users.unshift(user);
      // this.emitUserSubject();
      this.getUsers();
    },
    (error: any) => {
    },
  );
}
updateUser(id, newUser: User) {
  this.api.updateUser(id, newUser).subscribe(
    (user: User) => {
      this.users.unshift(user);
      // this.emitUserSubject();
      this.getUsers();
    },
    (error: any) => {
    },
  );
}
}
