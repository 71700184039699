import { ResourceActionService } from './../base/resource-action.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppApiInvoiceAdvanceService  extends ResourceActionService {
  url = '/invoiceAdvances';
}

